.hide_right {
    transform: translateX(50px);
    opacity: 0;
    pointer-events: none;
}

.show_right {
    transform: translateX(0) !important;
    opacity: 1 !important;
    pointer-events: auto !important;
}

.project_section {
    height: 410vh;
}

.project_header {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bolder;
    background-image: linear-gradient(transparent, 70%, black);
}

.project_content {
    background-color: black;
    height: 100vh;
}

.project_images {
    height: 300px;
    width: 300px;
    object-fit: cover;
    position: absolute;
    transition: transform .2s ease-in-out;
    border: var(--theme-color) 2px solid;
}

.project_images:hover {
    z-index: 10 !important;
    transform: translateY(-5px);
}

.project_images.focus {
    transform: scale(2);
    z-index: 11 !important;
}

.project_images#project_img1 {
    z-index: 1;
}

.project_images#project_img2 {
    margin: 5% 0 0 5%;
}

.project_images#project_img3 {
    z-index: 0;
    margin: -5% 0 0 4%;
}

.project_hero_image {
    height: 400px;
    width: 400px;
    object-fit: cover;
    position: absolute;
    z-index: 1;
    left: calc(50vw - 200px);
    /* top: -40px; */
    border-radius: 10px;
    transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.project_hero_image.shrink {
    height: 200px;
    width: 200px;
    left: calc(50vw - 100px);
    border: 5px var(--theme-color) solid;
}

.project_hero_image:hover {
    cursor: pointer;
    transform: scale(1.025);
}

.project_hero h1 {
    position: relative; 
    z-index: 2;
    margin-left: 15%;
    font-size: 30px;
    font-weight: bolder;
    text-shadow: var(--theme-color) 0 0 25px !important;
}

.project_hero h1::before {
    z-index: 1;
    content: '';
    height: 2px;
    width: 100px;
    background-color: var(--theme-color);
    position: absolute;
    transform: translate(-30px, 50px);
}

.project_hero {
    position: relative;
}

.project_display {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 250px;
    gap: 20px;
    right: 5%;
    transition: transform .5s ease-in-out, opacity 1s ease-in-out;
}

.extra_container {
    position: absolute;
    height: 100vh;
    width: 100%;
}

.project_text {
    max-width: 300px;
}

/* Image Shapes */
.project_supporting_images .rectangle {
    object-fit: contain;
    height: 120%;
    width: fit-content;
    margin-left: 180px !important;
    margin-top: -150px !important;
}

.ball_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    left: calc(15% + 10px);
    top: 10%;
    width: fit-content;
    gap: 20px;
    position: absolute;

}

.ball {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-weight: bold;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background-color: transparent;
    border: 2px var(--theme-color) solid;
    box-shadow: 0px 0px 16px var(--theme-color);
    position: relative;
}

.ball#tech1 {
    animation: floating 5.5s ease-in-out infinite;
}
.ball#tech2 {
    animation: floating 6.5s ease-in-out infinite;
}
.ball#tech3 {
    animation: floating 6s ease-in-out infinite;
}
.ball#tech4 {
    animation: floating 6.25s ease-in-out infinite;
}
.ball#tech5 {
    animation: floating 5s ease-in-out infinite;
}

.detail_section {
    height: 105%;
    padding: 20px;
    position: relative;
}

.detail_section.black {
    color: black;
    background-color: white;
}

.detail_section.white {
    color: white;
    background-color: black;
}

/* Animations */
@keyframes floating {
    0%, 100% {
        transform: translateY(3px);
    }

    50% {
        transform: translateY(-3px);
    }
}