.banner {
    position: relative;
    height: 100%;
    width: 100vw;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.row {
    position: relative;
    padding: 10px 20px 0 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    gap: 20px;
}

#row2::before {
    background-image: none !important;
}

.row::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    filter: blur(2px);
}

.overlayContainer {
    position: fixed;
    display: flex;
    z-index: 10;
    justify-content: end;
    background-color: rgba(0, 0, 0, 0.5);
    height: 90vh;
    width: 100%;
    top: 0;
    left: 0; 
    right: 0;
    bottom: 0;
}

.overlay {
    width: 100%;
    height: 100%;
    bottom: inherit;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.overlayContainer::before {
    /* z-index: 10; */
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 20px;
    padding: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
}

#close_overlay {
    position: absolute;
    margin-right: 10px;
    font-weight: bolder;
    font-size: 80px;
    background: none;
    color: white;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: white;
    height: 40px;
    width: 40px;
}

.hidden {
    display: none;
}

#box1 {
    background-image: url("../imgs/pokemonart.jpeg");
}

#box2 {
    background-image: url("../imgs/kaze.jpg");
}

#box3 {
    background-image: url("../imgs/lenny.jpg");
}

#box4 {
    background-image: url("../imgs/spacedude.jpg")
}

#box5 {
    background-image: url("../imgs/Scattered_Lions.jpg")
}

.selected {
    height: 350px !important;
    width: 350px !important;
}

.threebox {
    display: block;
    position: relative;
    border-radius: 20px 0 20px 0;
    height: 320px;
    width: 320px;
    bottom: 0;
    background-size: cover;
    transition: bottom 1s ease-out;
}

.caption {
    pointer-events: none;
    width: 100%;
}

.caption::after {
    content: attr(data-caption);
    padding-left: 5px;
    pointer-events: none !important;
    font-size: 17px !important;
    color: black;
    display: flex;
    align-items: flex-end;
    height: 320px;
    width: 100%;
    position: absolute;
    background-color: white;
    left: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 20px 0 20px 0;
    transition: bottom 1s ease-out;
}

.threebox:hover {
    bottom: 30px;
}

.threebox:hover > .caption::after {
    bottom: -30px;
}

.largebox.selected {
    height: 350px !important;
    width: 680px !important;
}

.largebox {

    border-radius: 20px 0 20px 0;
    height: 320px;
    width: 650px;
    background-size: cover;
    transition: bottom 1s ease-out;
}

@keyframes emphasize {

    0% {
        z-index: 5;
        transform: scale(1);
    }

    100% {
        z-index: 5;
        transform: scale(1.1);
    }
}

.welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: larger;
    z-index: 1;
    margin: 200px 0 200px 0;
}

.welcome img {
    width: 300px;
}

@keyframes beforeanim {
    0%, 100% {
        transform: translateX(-30px);
    }
    50% {
        transform: translateX(30px)
    }
}

@keyframes afteranim {
    0%, 100% {
        transform: translateX(30px);
    }
    50% {
        transform: translateX(-30px)
    }
}

#outro {
    text-align: center;
    margin: 30px 0 30px 0;
    color: white;
}